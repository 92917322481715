import React, { useRef } from 'react';
import ReactDOM from 'react-dom';
import * as PropTypes from 'prop-types';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useAdvancedSearch } from '../hooks/useAdvancedSearch';
import {
    useQueryParams,
    StringParam,
    withDefault,
    encodeQueryParams,
} from 'use-query-params';
import { stringify } from 'query-string';
import { ArrayOfObjectsParam } from '../hooks/utils';
import { Cookies } from 'react-cookie';
import { v4 as uuidv4 } from 'uuid';
import {
    AND,
    ANY,
    ASSET_TYPES,
    CONTAINS,
    RESOURCE_TYPE,
} from '../views/AdvancedSearch/SearchConstants';
import {
    buildQuery,
    SearchBuilder,
} from '../views/AdvancedSearch/SearchBuilder';
import './WpPageSearch.scss';

const target = document.getElementById('wpPageSearch');

const SEARCH_PATH = '/search/:view';

/**
 * A search component only displayed in portals, i.e., divs in page with id='wpPageSearch'
 * This is a copy of BasicSearch.js with modifications to make it only a portal,
 * And the search call hard-coded here, as well as adding the asset type filter dropdown.
 *
 * @param props
 * @returns {React.ReactPortal|JSX.Element}
 * @constructor
 */
export function WpPageSearch(props) {
    const history = useHistory();
    const inputEl = useRef(null);
    const updateStoredRows = useAdvancedSearch((state) => state.updateRows);
    const cookie = new Cookies();
    let searchview = cookie?.get('searchview');
    if (!searchview || searchview.length == 0) {
        searchview = process.env?.REACT_APP_SEARCH_VIEW_DEFAULT || 'list';
        cookie.set('searchview', searchview);
    }

    // This tells us whether we are viewing the search results
    // so that we can give a link to go there (or not).
    const searchView = useRouteMatch(SEARCH_PATH);

    const [query, setQuery] = useQueryParams({
        searchText: StringParam,
        filters: withDefault(ArrayOfObjectsParam, []),
    });
    let { searchText: search, filters } = query;

    const handleSubmit = () => {
        document.getElementById('advanced-search-tree-toggle').click();
        const inputNoQuotes = inputEl?.current?.value
            ? inputEl.current.value.trim()
            : '';
        // console.log("input no quotes", `'${inputNoQuotes}'`);
        // example: advSearch:((names:cat)^100 (titles:cat)^100 (creators:cat)^100 (text:"cat")^90 (titles:*cat*)^85
        // (creators:*cat*)^85 (names:*cat*)^85) AND (asset_type:audio-video)
        const rows = [
            {
                isdate: false,
                conn: false,
                field: ANY,
                scope: CONTAINS,
                text: inputNoQuotes,
                searchDisabled: false,
                id: uuidv4(),
            },
        ];
        const atypeval = document.getElementById('at-select').value;
        if (atypeval && atypeval.length > 0 && atypeval !== 'all') {
            rows.push({
                isdate: false,
                conn: AND,
                field: RESOURCE_TYPE,
                scope: atypeval,
                text: '',
                searchDisabled: false,
                id: uuidv4(),
            });
        }
        updateStoredRows(rows);
        const newqry = buildQuery(rows);
        document.getElementById('advanced-search-tree-toggle').click();
        if (!searchView) {
            const encodedQuery = encodeQueryParams(
                {
                    searchText: StringParam,
                    filters: withDefault(ArrayOfObjectsParam, []),
                },
                {
                    searchText: `advSearch:${newqry}`,
                    filters: [...filters],
                }
            );
            if (process.env.REACT_APP_STANDALONE === 'standalone') {
                window.location.hash = `#/search/${searchview}?${stringify(
                    encodedQuery
                )}`;
            } else {
                //history.push('/search/deck');
                history.push({
                    pathname: `/search/` + searchview,
                    search: `?${stringify(encodedQuery)}`,
                });
            }
        } else {
            console.log('newqry is', newqry);
            setQuery(
                {
                    searchText: `advSearch:${newqry}`,
                    filters: [...filters],
                },
                'push'
            );
        }
    };
    const clearInput = () => {
        inputEl.current.value = '';
        setQuery(
            {
                searchText: '',
                filters,
            },
            'push'
        );
    };
    const handleKey = (x) => {
        // submit on return
        if (x.keyCode === 13) {
            handleSubmit();
        }
    };

    function cleanSearch(srch) {
        let newSearch = '';
        if (!srch.startsWith('advSearch')) {
            newSearch = srch;
        }
        return newSearch;
    }
    // console.log("search in basic search", search);

    const searchVal = decodeURIComponent(
        cleanSearch(encodeURIComponent(search || ''))
    );

    let atypes = {
        all: 'All',
        ...ASSET_TYPES,
    };
    const assetTypeSelect = (
        <select id="at-select" name="asset_type">
            {Object.keys(atypes).map((atk, atki) => {
                const selval = atk === target?.dataset?.filter;
                return (
                    <option
                        value={atk}
                        key={`at-choice-${atki}`}
                        selected={selval}
                    >
                        {atypes[atk]}
                    </option>
                );
            })}
        </select>
    );

    const wpPageSearchPortal = (
        <div className="sui-search1">
            {assetTypeSelect}
            <input
                key={search}
                type="text"
                id="sui-search"
                className="sui-search2"
                defaultValue={searchVal}
                placeholder="Search &amp; Explore!"
                onKeyDownCapture={handleKey}
                ref={inputEl}
            />

            <span id="sui-clear" className="sui-search3" onClick={clearInput}>
                {' '}
            </span>
            <span
                id="sui-searchgo"
                className="sui-search4"
                onClick={handleSubmit}
            >
                <span className={'icon shanticon-magnify'}></span>
            </span>
        </div>
    );

    if (target) {
        return ReactDOM.createPortal(wpPageSearchPortal, target);
    } else {
        return null;
    }
}

WpPageSearch.propTypes = { onChange: PropTypes.func };
