import * as SC from './SearchConstants';
import {
    LAST1YEAR,
    PUB_PLACE,
    SOLRFIELDS,
    TIB_FIELDS,
} from './SearchConstants';
import { isLat, isTib } from '../common/utils';
import { constructTextQuery } from '../../hooks/useSearch';

/**
 * Main Class is SearchBuilder. It uses a secondary class QueryItem which processes each line from the form
 *
 * sample query in form:
         FIRST|TITLE|CONTAINS|meditation
         OR|PERSON|STARTSWITH|David
 */

export class SearchBuilder {
    /**
     * Search Builder: A JS class to construct SOLR queries from a list of data items.
     *
     * Initialize with a array of data items which are strings separated by a delim or
     * an array of already split up arrays
     * @param data
     * @param delim
     */
    constructor(data, delim = '|') {
        this.data = data;
        this.delim = delim;
        this.lines = '';
        this.items = '';
        this.query = '';
        this.init();
    }

    init() {
        this.lines = this.processData();
        this.items = this.convertLines();
    }

    /**
     * Process the list of items into normalized, list of lists, each with 5 items:
     *      connector, field, scope, search string, and isdate
     * @returns {*}
     */
    processData() {
        const data = this?.data;
        let lines = [];
        if (!data) {
            return lines;
        }
        // If the data list is already a list of 4-item lists just return it
        if (Array.isArray(data)) {
            if (data?.length === 0) {
                return false; // Return false when data is not in the correct format.
            }
            lines = data;
        } else if (data instanceof String) {
            lines = data.split('\n');
        }
        // otherwise, break each item up. If it is a string with delimiter or object with named propreties
        return lines.map((item, itn) => {
            // console.log('item ' + itn, item);
            if (item instanceof Object) {
                const scpval = isNaN(item?.scope * 1)
                    ? item?.scope
                    : item?.scope * 1;
                let res = [
                    item?.conn * 1,
                    item?.field * 1,
                    scpval,
                    item?.text,
                    item?.isdate,
                ];
                return res;
            } else if (item instanceof String && item.includes(this.delim)) {
                // TODO: deal with "isdate" in delimited string if need be
                return item.split(this.delim).map((pt, ptn) => {
                    if (!isNaN(pt * 1)) {
                        return pt * 1;
                    }
                    return pt;
                });
            } else {
                console.log(
                    'Mandala Search Builder: delim not found in item #' + itn
                );
            }
        });
    }

    convertLines() {
        return this?.lines?.map((ln, lnn) => {
            if (Array.isArray(ln)) {
                return new QueryItem(...ln);
            }
        });
    }

    buildQuery() {
        let qs = '';
        this.items.map((itm, itmn) => {
            qs += itm?.getQuery();
        });
        this.query = qs;
        return this.query;
    }

    /**
     * Returns a description of the query
     */
    describeQuery() {
        let qdesc = '';
        this.items.map((itm, iind) => {
            qdesc += itm?.getDesc();
        });
        return qdesc;
    }
}

class QueryItem {
    /**
     * Builds a single query item (equivalent to one line from the form)
     * Values for conn, field, and scope must be the integer values defined in SearchConstants.js
     * And must have the corresponding key defined in SearchConstants.SOLRFIELDS object
     *
     * @param conn
     * @param field
     * @param scope
     * @param qstr
     */
    constructor(conn, field, scope, qstr, isdate) {
        //console.log("Constructior: field is:", field);
        // console.log('qstr', qstr);
        this.conn = conn;
        this.connstr = this.getConnector(this.conn);
        this.field = field;
        this.fieldlist = this.getFields(this.field, scope, qstr);
        this.scope = scope;
        this.orig_qstr = qstr;
        this.qstr = qstr.toLowerCase();
        this.query = ';';
    }

    // Get a descriptive string of query row
    getDesc() {
        let lndesc = '';
        switch (this.conn) {
            case SC.AND:
                lndesc += ' + ';
                break;
            case SC.OR:
                lndesc += ' | ';
                break;
            case SC.ANDNOT:
                lndesc += ' - ';
                break;
            default:
            // add nothing for first row
        }
        if (this.field === SC.RESOURCE_TYPE) {
            lndesc += SC.ASSET_TYPES[this.scope];
        } else {
            lndesc += this.orig_qstr;
        }

        return lndesc;
    }

    getQuery() {
        // builds the query for this item
        // If an ANY field has no text, return nothing to add to query so it doesn't break (See MANU-7452)
        if (this.field === SC.ANY && this.qstr.length === 0) {
            return '';
        }
        this.query = `${this.connstr}(${this.buildQueryString()})`; // Saves in class for later use
        return this.query; // But also returns it for use outside class
    }

    getConnector(conn) {
        switch (conn) {
            case SC.AND:
                return ' AND ';
            case SC.OR:
                return ' OR ';
            case SC.ANDNOT:
                return ' AND -';
            default:
                return '';
        }
    }

    getFields(field, scp, qry) {
        if (field) {
            field = isNaN(field) ? field : field.toString();
            if (
                field * 1 === SC.ANY &&
                [SC.STARTSWITH, SC.ENDSWITH].includes(scp)
            ) {
                field = SC.ANYSPECIAL.toString(); // Cannot use text general for begins or ends with (MANU-7665)
            }
            if (Object.keys(SC.SOLRFIELDS).includes(field)) {
                return SC.SOLRFIELDS[field];
            }
        }
        return ['text']; // default to 'text' = all fields (the "any" option)
    }

    // TODO: Need to deal with "and not". To make the minus work the item's string needs to be in parentheses.
    buildQueryString() {
        let query = '';
        const tibstops = '་།༑༔༏'; // Tibetan stop characters TODO: use codes here, make more comprehensive

        this.fieldlist.map((fld, fn) => {
            // When "Any" option is chosen, the fld = "text"
            const myqstr = this?.qstr.trim();
            let escqs = myqstr.replace(/\s+/g, '\\ ').replace(/"/g, ''); // Slash escape spaces to search for whole phrase
            // The use of sswords and allwords has been replaced by calling constructTextQuery() from useSearch below.
            // const sswords = myqstr?.toLowerCase().trim().split(' ');
            // const allwords = sswords.join(' AND ');
            const slashy = escqs + '/';
            if (tibstops.includes(escqs.at(-1))) {
                escqs = escqs.substring(0, escqs.length - 1);
            }
            if (fn > 0 && fn < this.fieldlist.length) {
                query += ' '; // was ' OR ' but to do weights we just need spaces
            }
            if (fld === 'name_roman.scholar' || fld.includes('bo_latn')) {
                if (isLat(escqs) && escqs.charAt(escqs.length - 1) !== '/') {
                    escqs = slashy;
                }
            } else if (SC.TIB_FIELDS.includes(fld)) {
                if (isTib(escqs)) {
                    // console.log(escqs, "is Tibetan for ", fld);
                    const lastchar = escqs.charCodeAt(escqs.length - 1);
                    // Remove final tsek if Tibetan to match exact searches
                    if (3850 < lastchar < 3853) {
                        escqs = escqs.substring(0, escqs.length - 1);
                    }
                }
            }
            switch (this?.scope) {
                case SC.EXACTLY:
                    // here fld is the name of the solr field so have to match on that. If "any" exactly,
                    if (fld === SC.SOLRFIELDS[SC.ANY][0]) {
                        // then build list of field names to iterate through (all possible fields to exact match on)
                        // See https://uvaissues.atlassian.net/browse/MANU-7637?focusedCommentId=86272
                        // SC.TITLE includes 'titles' and 'names'
                        const fldnms = SC.SOLRFIELDS[SC.TITLE].concat(
                            SC.SOLRFIELDS[SC.PERSON],
                            SC.SOLRFIELDS[SC.PUB_PLACE],
                            SC.SOLRFIELDS[SC.PUBLISHER]
                        );
                        // if any, use title (which includes names) for now, since "text" won't work.
                        const qitems = fldnms.map(
                            (item) => `${item}:"${escqs}"`
                        );
                        query += qitems.join(' '); // was ' OR ' but can just do space
                    } else {
                        query += `${fld}:"${escqs}"`;
                    }
                    break;
                case SC.STARTSWITH:
                    query += `${fld}:${escqs}*`;
                    break;
                case SC.ENDSWITH:
                    query += `${fld}:*${escqs}`;
                    break;
                case SC.LAST1YEAR:
                    query += `${fld}:[NOW-1YEAR TO NOW]`;
                    break;
                case SC.LAST5YEARS:
                    query += `${fld}:[NOW-5YEAR TO NOW]`;
                    break;
                case SC.LAST10YEARS:
                    query += `${fld}:[NOW-10YEAR TO NOW]`;
                    break;
                case SC.BETWEEN:
                    if (escqs.includes('-')) {
                        const [stdt, enddt] = escqs.split('-');
                        query += `${fld}:[${stdt} TO ${enddt}]`;
                    } else {
                        console.log(
                            'Query string needs to be two SOLR dates separated by a dash, using default query'
                        );
                        query += '*:*';
                    }
                    break;
                default:
                    // "Contains"
                    if (this.field === SC.RESOURCE_TYPE) {
                        query += `${fld}:${this.scope}`; // when search on asset type, scope is what you are looking for and fld is "asset_type"
                    } else if (fld === 'text') {
                        const basic_req = constructTextQuery(myqstr);
                        query = basic_req.q;
                        // console.log('query in new search builder', query);
                    } else {
                        query += `(${fld}:${escqs})^100 (${fld}:*${escqs}*)^85`;
                        // console.log('query', query);
                    }
            }
        });
        return query;
    }
}

export function buildQuery(rows) {
    const builder = new SearchBuilder(rows);
    const query = builder.buildQuery();
    // console.log('the query', query);
    return query;
}

export function getReadableQuery(rows) {
    const tr = SC.corr;
    const qsum = [];
    for (let i in rows) {
        let { isdate, conn, field, scope, text } = rows[i];
        if (i > 0) {
            qsum.push(tr[conn]);
        }
        qsum.push(tr[field]);
        qsum.push(tr[scope]);
        if (text?.length > 0) {
            qsum.push(`“${text}”`);
        }
    }
    return qsum.join(' ');
}
