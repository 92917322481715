import React, { useEffect, useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import KmapTree from '../../KmapTree/KmapTree';
import HistoryViewer from '../../History/HistoryViewer';
import { useKmapRelated } from '../../../hooks/useKmapRelated';
import { useUnPackedMemoized } from '../../../hooks/utils';
import { getProject, queryID } from '../../../views/common/utils';
import './RelatedsViewer.scss';
import { useHistory } from '../../../hooks/useHistory';
import MandalaSkeleton from '../../common/MandalaSkeleton';
import { useKmapRelatedTerms } from '../../../hooks/useKmapRelatedTerms';

export function RelatedsViewer() {
    let statePages = useHistory((state) => state.pages);
    /*
    // Remove current page from list so that it doesn't show
    statePages = Array.from(statePages);
    statePages = statePages.filter((sp) => {
        return !sp.includes('::' + window.location.pathname);
    });
    */
    const match = useRouteMatch([
        '/:baseType/:id/related-:type/:definitionID/view/:relID',
        '/:baseType/:id/related-:type/:definitionID/:viewMode',
        '/:baseType/:id/related-:type',
        '/:baseType/:id',
    ]);

    const loc = match?.params.type || 'home';
    let locMatch = {};
    locMatch[loc] = 'active';

    const [relCount, setRelCount] = useState(0);

    let baseArgs = {
        baseType: match?.params.baseType,
        id: match?.params.id,
    };

    const {
        isLoading: isRelatedLoading,
        data: relatedData,
        isError: isRelatedError,
        error: relatedError,
    } = useKmapRelated(queryID(baseArgs.baseType, baseArgs.id), 'all', 0, 100);

    //Unpack related data using memoized function
    const kmapsRelated = useUnPackedMemoized(
        relatedData,
        baseArgs.id,
        'all',
        0,
        100
    );

    const {
        isLoading: isRelatedSubjLoading,
        data: relatedSubjData,
        isError: isRelatedSubjError,
        error: relatedSubjError,
    } = useKmapRelatedTerms(
        queryID(baseArgs.baseType, baseArgs.id),
        'all',
        0,
        100,
        'noDefID',
        false
    );

    /*
    useEffect(() => {
        //Set related child subject count from second query
        const relchildct = relatedSubjData?.response?.numFound * 1;
        if(baseArgs?.relateds?.assets?.subjects?.count) {
            console.log("Subject count", baseArgs.relateds.assets.subjects.count);
        }
        if (!isNaN(relchildct)) {
            setRelCount(relchildct)
        }

    }, [relatedSubjData, baseArgs]);

     */

    if (!baseArgs.id || !baseArgs.baseType) {
        return null;
    }

    if (isRelatedLoading) {
        return (
            <aside className="l-column__related">
                <div className="l-column__related__wrap">
                    <section className="l-related__list__wrap">
                        <MandalaSkeleton
                            count={10}
                            height={25}
                            width={'100%'}
                            marginTop={12}
                        />
                    </section>
                </div>
            </aside>
        );
    }

    if (isRelatedError) {
        return (
            <aside className="l-column__related">
                <div className="l-column__related__wrap">
                    <section className="l-related__list__wrap">
                        <span>Error: {relatedError.message}</span>
                    </section>
                </div>
            </aside>
        );
    }

    baseArgs.relateds = kmapsRelated;
    const totalRelateds = kmapsRelated?.assets?.all.count || 0;
    const noneClass = totalRelateds === 0 ? ' none' : '';
    // To Increase counts by another search other than the standard relateds (e.g. terms query for related child subjects)
    // These values are added to the baseArgs.relateds.{asset type}.count value when displayed. Must be keyed on asset type
    baseArgs.augments = {
        subjects: relatedSubjData?.response?.numFound * 1,
    };
    let allct = 0;
    for (const [key, val] of Object.entries(baseArgs.augments)) {
        allct += val * 1;
    }
    baseArgs.augments.all = allct;

    // Link class was: c-related__link--home c-related__item nav-item nav-link ${locMatch['home']}
    return (
        <aside className={'l-column__related'}>
            <div className="l-column__related__wrap">
                <section className="l-related__list__wrap">
                    {totalRelateds === 0 && (
                        <>
                            <div className="u-related__list__header">
                                Related Resources
                            </div>
                            <ul>
                                <li>
                                    <span>None</span>
                                </li>
                            </ul>
                        </>
                    )}
                    {totalRelateds > 0 && (
                        <>
                            <div className="u-related__list__header">
                                <Link
                                    id="sui-rl-Home"
                                    to={
                                        '/' +
                                        baseArgs.baseType +
                                        '/' +
                                        baseArgs.id
                                    }
                                    className={``}
                                >
                                    Overview
                                </Link>
                            </div>
                            <hr />
                            <div className="u-related__list__header">
                                Related Resources
                            </div>
                            <nav
                                className={`c-relatedViewer nav nav-tabs flex-column${noneClass}`}
                            >
                                <RelatedCount
                                    type={'all'}
                                    {...baseArgs}
                                    role={'tab'}
                                    className={locMatch['all']}
                                />
                                <RelatedCount
                                    type={'subjects'}
                                    {...baseArgs}
                                    role={'tab'}
                                    className={locMatch['subjects']}
                                />
                                <RelatedCount
                                    type={'places'}
                                    {...baseArgs}
                                    role={'tab'}
                                    className={locMatch['places']}
                                />
                                <RelatedCount
                                    type={'terms'}
                                    {...baseArgs}
                                    role={'tab'}
                                    className={locMatch['terms']}
                                />
                                <RelatedCount
                                    type={'collections'}
                                    {...baseArgs}
                                    role={'tab'}
                                    className={locMatch['collections']}
                                />
                                <RelatedCount
                                    type={'audio-video'}
                                    {...baseArgs}
                                    role={'tab'}
                                    className={locMatch['audio-video']}
                                />
                                <RelatedCount
                                    type={'images'}
                                    {...baseArgs}
                                    role={'tab'}
                                    className={locMatch['images']}
                                />
                                <RelatedCount
                                    type={'sources'}
                                    {...baseArgs}
                                    role={'tab'}
                                    className={locMatch['sources']}
                                />
                                <RelatedCount
                                    type={'texts'}
                                    {...baseArgs}
                                    role={'tab'}
                                    className={locMatch['texts']}
                                />
                                <RelatedCount
                                    type={'visuals'}
                                    {...baseArgs}
                                    role={'tab'}
                                    className={locMatch['visuals']}
                                />
                            </nav>
                        </>
                    )}
                </section>
                {/* Removed per https://uvaissues.atlassian.net/browse/MANU-7535 (07/18/23)
                {statePages.length > 0 && (
                    <section className="l-history__list__wrap">
                        <div className="u-related__list__header">
                            Recently Viewed
                        </div>
                        <HistoryViewer />
                    </section>
                )}
                /*}
                {/* <section className="l-related__tree__wrap">
                    <div className="u-related__list__header">
                        Browse{' '}
                        <span className={'text-capitalize'}>
                            {baseArgs.baseType}
                        </span>
                    </div>

                    <SideTree {...baseArgs} />
                </section> */}
            </div>
        </aside>
    );
}

function RelatedCount(props) {
    let count = props.relateds?.assets
        ? props.relateds.assets[props.type]?.count
        : 0;
    const augment = props?.augments[props.type] || 0;
    count += augment;
    // assign shanticon class according to type.  "all" type should get the "shanticon-logo-shanti" icon.
    const iconClass =
        'icon u-icon__' + (props.type === 'all' ? 'logo-shanti' : props.type);

    let display = 'deck';
    const listTypes = ['places', 'sources', 'subjects', 'texts'];
    if (listTypes.includes(props.type)) {
        display = 'list';
    }
    if (props.type === 'images') {
        display = 'gallery';
    }

    // Link path.
    let path =
        '/' +
        props.baseType +
        '/' +
        props.id +
        '/related-' +
        props.type +
        '/' +
        (props.baseType === 'terms' ? 'any/' : '') +
        display;

    let activeClass = props.className || '';

    // return null if the count doesn't exist or is === 0
    return count ? (
        <Link
            id={'sui-rl-' + props.type}
            href="#"
            className={`nav-item nav-link c-related__item c-related__link--${props.type} ${activeClass}`}
            role={'tab'}
            to={path}
        >
            <span className={'u-icon__' + props.type + ' ' + iconClass}></span>
            <span className={'c-related__item__label'}> {props.type}</span>
            <span id="sui-rln-places">{count}</span>
        </Link>
    ) : null;
}

// eslint-disable-next-line no-unused-vars
function SideTree(props) {
    // Determine which tree (browse_tree) to display in the relateds sidebar
    const domain = props.baseType;
    const kid = props?.id ? props.id : false;
    const fid = kid ? queryID(domain, kid) : false;
    // places settings
    let level = false;
    let showAnc = false;

    // Subject settings
    if (domain === 'subjects') {
        level = 1;
        showAnc = false;
        // Term settings
    } else if (domain === 'terms') {
        level = 1;
        showAnc = false;
    }
    return (
        <KmapTree
            elid={`related-tree-left-${domain}`}
            className="l-relatedTreeLeft"
            domain={domain}
            selectedNode={fid}
            project={getProject()}
            showAncestors={showAnc}
            level={level}
        />
    );
}
