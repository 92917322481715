import React, { useEffect, useState, useContext } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Col } from 'react-bootstrap';
import TranslationInstructions from './TranslationInstructions';

export default function TranslationForm({ submit, resultsVisible }) {
    const checkForEnter = (e) => {
        if (e?.which === 13 && !e.shiftKey) {
            e.preventDefault();
            submit(e);
        }
    };
    return (
        <Form id="tt-form" className="transtool-form" onSubmit={submit}>
            <Form.Row>
                <Col>
                    <Form.Group
                        className="mb-3"
                        controlId="translationForm.tibetan"
                    >
                        <Form.Control
                            as="textarea"
                            rows={3}
                            onKeyDown={checkForEnter}
                        />
                    </Form.Group>
                    <Form.Group className="btns">
                        <Button
                            variant="primary"
                            type="submit"
                            className="submit"
                        >
                            Translate
                        </Button>
                        <Button
                            variant="primary"
                            type="reset"
                            className="reset"
                            onClick={() => {
                                resultsVisible(false);
                            }}
                        >
                            Reset
                        </Button>
                    </Form.Group>
                </Col>
                <Col>
                    <TranslationInstructions />
                </Col>
            </Form.Row>
        </Form>
    );
}
