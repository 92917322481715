import { useQuery } from 'react-query';
import axios from 'axios';

/**
 * UseParser calls an API in a Wordpress site that has the Mandala WP Plugin (see repo: https://github.com/shanti-uva/wp-kmaps)
 * The parser breaks the Tibetan into phrases and phrases into words, which it then translates. It does the first phrase
 * and returns data including a list of subsequent phrases. Subsequent calls can be made with this data to second hook
 * here called "useLoadParserData()". This will parse and translate the next phrase etc so that the translation
 * equivalents can be progressively loaded, giving the illusion of a quicker overall time.
 *
 * Thus, there are two hooks here: useParser() for the first call and useLoadParserData() for subsequent ones.
 */
const TP_DOMAIN = process.env?.REACT_APP_TIB_PARSE_DOMAIN
    ? process.env.REACT_APP_TIB_PARSE_DOMAIN
    : process.env.REACT_APP_PUBLIC_URL;
const TIBPARSE = TP_DOMAIN + '/wp-json/mandala/v1/parsetib?';

const getParsedPhrase = async (tibPhrase, done) => {
    if (tibPhrase?.length > 0) {
        let qurl = TIBPARSE + 'tib=' + encodeURIComponent(tibPhrase);
        if (done?.length > 0) {
            qurl += '&done=' + done;
        }
        const request = {
            /*adapter: jsonpAdapter,
            callbackParamName: 'mandala',*/
            url: qurl,
        };
        const { data } = await axios.request(
            request
            // TIBPARSE + encodeURIComponent(tibPhrase)
        );
        // console.log("Axios request params = ", myparams);
        // console.log('Axios request/response = ', request, data);
        return data;
    }
};

export function useParser(tibData, byPass = false, options = {}) {
    const newOptions = { enabled: !byPass, ...options };
    let tibPhrase = tibData;
    let done = '';
    if (typeof tibData === 'object') {
        tibPhrase = tibData?.undone;
        done = tibData?.done;
    }
    return useQuery(
        ['translate', tibPhrase],
        () => getParsedPhrase(tibPhrase, done),
        newOptions
    );
}

const getMoreTransData = async (datain) => {
    if (typeof datain === 'object') {
        datain = JSON.stringify(datain);
    }
    const qurl = TIBPARSE + 'data=' + datain;
    const request = {
        /*adapter: jsonpAdapter,
        callbackParamName: 'mandala',*/
        url: qurl,
    };
    const { data } = await axios.request(
        request
        // TIBPARSE + encodeURIComponent(tibPhrase)
    );
    // console.log("Axios request params = ", myparams);
    // console.log('Axios request/response = ', request, data);
    return data;
};

export function useLoadParserData(data, byPass = false, options = {}) {
    // console.log("data in useLoadParserData", data);
    const newOptions = { enabled: !byPass, ...options };
    return useQuery(
        ['translate', data?.tibetan, data?.phrases?.length],
        () => getMoreTransData(data),
        newOptions
    );
}
