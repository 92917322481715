import React, { useEffect, useState, useContext } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Col } from 'react-bootstrap';

export default function TranslationInstructions(props) {
    return (
        <div className="tt-instruct">
            <h2>Instructions</h2>
            <p>
                Enter a Tibetan word or phrase in either Unicode Tibetan or
                Wylie transliteration, and press "Translate". A list of Tibetan
                words, each with a list of definitions, will appear below. Click
                on the word itself to see its full entry in the dictionary.
                Click on a particular definition to choose that one and collapse
                the rest. Click on the{' '}
                <span className="icon shanticon-cancel-circle"></span> at the
                far right margin to remove the whole row from the list.
            </p>
        </div>
    );
}
