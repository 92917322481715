import React from 'react';

import './HistoryViewer.css';

import { Link, Redirect, useParams } from 'react-router-dom';
import { encodeQueryParams, StringParam, withDefault } from 'use-query-params';
import { useRecentSearch } from '../../hooks/useRecentSearch';
import { ArrayOfObjectsParam } from '../../hooks/utils';
import RecentSearchItem from './RecentSearchItem';
import { stringify } from 'query-string';
import { SearchBuilder } from '../AdvancedSearch/SearchBuilder';
import { encode } from 'qs/lib/utils';
import { useAdvancedSearch } from '../../hooks/useAdvancedSearch';
export function SearchRecent() {
    const { index } = useParams();
    const srchlist = useRecentSearch((state) => state.searches);
    const setStoredRows = useAdvancedSearch((state) => state.updateRows);
    const chosen_search = srchlist[index];
    setStoredRows(chosen_search['data']);
    const srchBuilder = new SearchBuilder(chosen_search['data']);

    const myfilters = chosen_search['filters'] || [];
    const searchstr =
        'filters=' +
        encodeURI(myfilters) +
        '&searchText=advSearch:' +
        encodeURI(srchBuilder.buildQuery());

    const loadSearchUrl = `/search/list?` + searchstr;

    return <Redirect to={loadSearchUrl} />;
}
