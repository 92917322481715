import React from 'react';
import ReactDOM from 'react-dom';
import { SearchBar } from './SearchBar';
import Button from 'react-bootstrap/Button';
import { SEARCH_COOKIE_NAME, SEARCH_PATH } from './SearchAdvanced';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import { ArrayOfObjectsParam } from '../hooks/utils';
import { useAdvancedSearch } from '../hooks/useAdvancedSearch';
import './SearchBoxAndButtons.scss';

/**
 * This code was included in SearchAdvanced.js to show the search box and links above that tab but then needed to
 * show above all tabs. So, pulled out as this separate component.
 *
 * @param showSearchBar
 * @param handleResetAll
 * @param filters
 * @returns {JSX.Element}
 * @constructor
 */

// Portal Element
const targetEl = document.getElementById('search-box-btns');

export function SearchBoxAndButtons({
    eid,
    showSearchBar,
    showClearAll,
    asPortal = false,
}) {
    const history = useHistory();
    const location = useLocation();
    const searchView = useRouteMatch(SEARCH_PATH);
    const updateStoredRows = useAdvancedSearch((state) => state.updateRows);

    const [query, setQuery] = useQueryParams({
        searchText: StringParam,
        filters: withDefault(ArrayOfObjectsParam, []),
    });

    let { searchText: search, filters: qfilters } = query;

    function backToSearchResults() {
        // In ContentMain whenever there is a route change the search string is save in localStorage
        // This is retrieved to return to search results if it is lost in the process of clicking around.
        let sqry = location?.search;
        if (!sqry) {
            sqry = localStorage.getItem(SEARCH_COOKIE_NAME);
        }
        history.push(`/search/list${sqry}`);
    }

    /* Old code? ndg8f Nov 9 2023
    function handleResetFilters() {
        setQuery(
            {
                searchText: search,
                filters: [],
            },
            'push'
        );
    } */

    function handleAdvancedSearchClick() {
        history.push('/advanced-search');
    }

    function handleResetAll() {
        setQuery(
            {
                searchText: '',
                filters: [],
            },
            'push'
        );
        localStorage.removeItem(SEARCH_COOKIE_NAME); // Remove the search string saved in the cookie
        updateStoredRows([]);
    }

    const sbab = (
        <div>
            {showSearchBar && (
                <div className="search-column-search-form">
                    <SearchBar eid={eid} />
                </div>
            )}
            <div className="search-column-reset-filters">
                {process.env.REACT_APP_STANDALONE !== 'standalone' &&
                    !searchView && (
                        <Button
                            onClick={backToSearchResults}
                            variant="link"
                            className={'back-to-results'}
                        >
                            <span className={'header-icon'}>
                                <span className="icon shanticon-magnify"></span>
                            </span>
                            View Results
                        </Button>
                    )}
                {process.env.REACT_APP_STANDALONE === 'standalone' &&
                    !searchView && (
                        <span className={'header-label-count back-to-results'}>
                            <a onClick={backToSearchResults}>
                                <span className={'header-icon'}>
                                    <span className="icon shanticon-magnify"></span>
                                </span>
                                View Results
                            </a>
                        </span>
                    )}

                {showClearAll && search && (
                    <button onClick={handleResetAll}>Clear All</button>
                )}
                <button onClick={handleAdvancedSearchClick}>
                    Advanced Search
                </button>
            </div>
        </div>
    );

    if (targetEl && asPortal) {
        return ReactDOM.createPortal(sbab, targetEl);
    } else {
        return sbab;
    }
}
