import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { stringify } from 'query-string';
import {
    useQueryParams,
    StringParam,
    withDefault,
    encodeQueryParams,
} from 'use-query-params';
import { v4 as uuidv4 } from 'uuid';
import { openTabStore, treeStore } from '../../hooks/useCloseStore';
import { browseSearchToggle } from '../../hooks/useBrowseSearchToggle';

import * as SC from './SearchConstants';
import { buildQuery, getReadableQuery, SearchBuilder } from './SearchBuilder';
import { ArrayOfObjectsParam } from '../../hooks/utils';
import './AdvancedSearch.scss';
// import { needsDateString, RESOURCE_TYPE } from './SearchConstants';
import { MdDeleteForever } from 'react-icons/all';
import Form from 'react-bootstrap/Form';
import { useAdvancedSearch } from '../../hooks/useAdvancedSearch';
import { fieldLookup } from './SearchConstants';
import { at_regex } from '../../search/BasicSearch';

const SEARCH_PATH = '/search/:view';
const searchview = 'list';

const rowid = (n) => {
    const rnd = Math.floor(Math.random() * 1000);
    return `query-row-${n}-${rnd}`;
};

const AdvancedSearch = () => {
    const history = useHistory();
    const firstid = rowid(0);
    const [qryrows, setRows] = useState([[firstid, 0, null, null]]);
    const [squery, setSQuery] = useState(false);
    const [delrow, setDel] = useState(false);
    const [showQuery, setShowQuery] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [stopAdd, setStopAdd] = useState(false);
    const storedRows = useAdvancedSearch((state) => state.rows);
    const updateStoredRows = useAdvancedSearch((state) => state.updateRows);
    // console.log({ storedRows });

    // This tells us whether we are viewing the search results
    // so that we can give a link to go there (or not).
    const searchView = useRouteMatch(SEARCH_PATH);
    const [query, setQuery] = useQueryParams({
        searchText: StringParam,
        filters: withDefault(ArrayOfObjectsParam, []),
    });
    let { searchText: search, filters } = query;

    useEffect(() => {
        const pgpath = window.location.pathname;
        const atmatch = pgpath.match(at_regex);
        if (atmatch) {
            const firstrow =
                storedRows?.length === 1
                    ? storedRows[0]
                    : {
                          isdate: false,
                          conn: false,
                          field: '10',
                          scope: '50',
                          text: '',
                          searchDisabled: false,
                          id: uuidv4(),
                      };
            const newStoredRows = [
                firstrow,
                {
                    isdate: false,
                    conn: '1',
                    field: '21',
                    scope: atmatch[1],
                    text: '',
                    searchDisabled: true,
                    id: uuidv4(),
                },
            ];
            updateStoredRows(newStoredRows);
        }
    }, []);

    useEffect(() => {
        if (delrow) {
            const rowids = qryrows.map((rw, rwi) => {
                return rw[0];
            });
            if (rowids?.includes(delrow)) {
                let newrows = [...qryrows];
                newrows = newrows.filter((rw, ri) => {
                    //return rw?.props?.id !== delrow;
                    return rw[0] !== delrow;
                });
                setRows(newrows);
            } else {
                setDel(false);
            }
        }
    }, [delrow]);

    useEffect(() => {
        if (errorMsg.length > 0) {
            document
                .getElementById('mdl-advsearch-form')
                .addEventListener('keypress', function () {
                    setErrorMsg('');
                });
        }
    }, [errorMsg]);

    const deleteRow = (id) => {
        setDel(id);
    };

    const reCreateForms = useCallback(() => {
        if (storedRows.length > 0) {
            // console.log("stored rows", storedRows);
            const newRows = storedRows.map((row, idx) => {
                const rowId = rowid(idx);
                return [rowId, idx, deleteRow, row];
            });
            setRows(newRows);
        }
    }, [storedRows]);

    useEffect(() => {
        reCreateForms();
    }, [storedRows, reCreateForms]);

    const addRow = (e) => {
        e.preventDefault();
        // If "stopAdd" state is true, means there was a return press and we should submit the form instead.
        // set by input to true when the enter key is pressed. Instead, submit form
        if (stopAdd) {
            setStopAdd(false);
            submitForm(e);
        } else {
            // Otherwise the add row button was pressed, so we should do that.
            let count = qryrows?.length || 0;
            const myid = rowid(count);
            const newrows = [...qryrows];
            newrows.push([myid, count, deleteRow, null]);
            setRows(newrows);
        }
    };

    const buildRowList = () => {
        const form = document.getElementById('mdl-advsearch-form');
        const rowels = form.querySelectorAll('.advsrch-form-row');
        const rowlist = [];

        rowels.forEach((el, n) => {
            const inputs = el.querySelectorAll('input, select');
            const isFirst = n === 0;
            const aug = isFirst ? 0 : 1;
            const fieldval = inputs[0 + aug].value;
            const isdate = SC.isDate(fieldval);
            const connval = !isFirst ? inputs[0].value : false;
            const scopeval = inputs[1 + aug].value;
            const textval = inputs[2 + aug].value;
            const textValIsDisabled = inputs[2 + aug].disabled;
            // Ignore rows with empty text boxes when relevant
            if (typeof textval === 'string' && textval.trim() === '') {
                if (
                    (isdate && SC.needsDateString(scopeval)) ||
                    fieldval === SC.RESOURCE_TYPE
                ) {
                    return;
                }
            }

            let row = {
                isdate: isdate,
                conn: connval,
                field: fieldval,
                scope: scopeval,
                text: textval,
                searchDisabled: textValIsDisabled,
                id: uuidv4(),
            };
            rowlist.push(row);
        });
        // console.log("Rowlist just built", rowlist);
        return rowlist;
    };

    const submitForm = (e) => {
        e.preventDefault();
        const rows = buildRowList();
        if (rows?.length > 0) {
            if (
                rows.length === 1 &&
                rows[0].isdate === false &&
                rows[0].text.length === 0
            ) {
                setErrorMsg(
                    'You must enter a string in the text box to create a valid search.'
                );
                return;
            }
            updateStoredRows(rows);
            const newqry = buildQuery(rows);
            setSQuery(newqry);
            document.getElementById('advanced-search-tree-toggle').click();
            if (!searchView) {
                const encodedQuery = encodeQueryParams(
                    {
                        searchText: StringParam,
                        filters: withDefault(ArrayOfObjectsParam, []),
                    },
                    {
                        searchText: `advSearch:${newqry}`,
                        filters: [...filters],
                    }
                );
                if (process.env.REACT_APP_STANDALONE === 'standalone') {
                    window.location.hash = `#/search/${searchview}?${stringify(
                        encodedQuery
                    )}`;
                } else {
                    //history.push('/search/deck');
                    history.push({
                        pathname: `/search/` + searchview,
                        search: `?${stringify(encodedQuery)}`,
                    });
                }
            } else {
                // console.log('newqry is', newqry);
                setQuery(
                    {
                        searchText: `advSearch:${newqry}`,
                        filters: [...filters],
                    },
                    'push'
                );
            }
        }
        return false;
    };

    const clearForm = (e) => {
        e.preventDefault();
        setErrorMsg('');
        setRows([[firstid, 0, null, null]]);
        updateStoredRows([]);
    };

    const revealQuery = (e) => {
        e.preventDefault();
        setShowQuery(true);
        const rows = buildRowList();
        if (rows?.length > 0) {
            const builder = new SearchBuilder(rows);
            const newqry = builder.buildQuery();
            setSQuery(newqry);
            if (e.target.id === 'show-results') {
                let surl =
                    'https://mandala-index.internal.lib.virginia.edu/solr/kmassets/select?fl=*&wt=json&echoParams=explicit&q=' +
                    newqry;
                surl = encodeURI(surl);
                window.open(surl, '_blank');
            }
        }
    };

    const readableQuery = (e) => {
        e.preventDefault();
        setShowQuery(true);
        const rows = buildRowList();
        const rq = getReadableQuery(rows);
        setSQuery(rq);
    };

    return (
        <div className="advanced-search-wrap">
            <form
                id="mdl-advsearch-form"
                className="search-form"
                data-rows={qryrows?.length}
            >
                <div className="mb-3">
                    <h4 className="title">Advanced Search</h4>

                    {errorMsg.length > 0 && (
                        <div className="messages alert alert-warning">
                            {errorMsg}
                        </div>
                    )}

                    <div className="query-rows">
                        <div className="query-rows-wrapper">
                            {qryrows.map((rw, rind) => {
                                if (rw.length === 4) {
                                    return (
                                        <QueryRow
                                            key={rw[0]}
                                            id={rw[0]}
                                            n={rind}
                                            delfunc={deleteRow}
                                            storedObj={rw[3]}
                                            totalrows={qryrows.length}
                                            stopAdd={setStopAdd}
                                            submit={submitForm}
                                        />
                                    );
                                } else {
                                    console.log(
                                        'Query row without four items',
                                        rw
                                    );
                                }
                            })}
                            <div className="add-btns">
                                <button
                                    className="btn btn-secondary btn-lg rounded"
                                    onClick={addRow}
                                    onKeyPress={(event) => {
                                        console.log(event);
                                    }}
                                >
                                    Add Row
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <hr className="my-4"></hr>
                <div className="action-btns">
                    <button
                        className="btn btn-primary btn-lg m-2"
                        onClick={submitForm}
                    >
                        Submit
                    </button>
                    ||
                    <button
                        className="btn btn-outline-primary btn-lg m-2"
                        onClick={clearForm}
                    >
                        Clear
                    </button>
                </div>
            </form>
            <div className="card output d-none">
                <div className="card-header">
                    {'Current Query (Show ... '}
                    <a onClick={revealQuery} className="action">
                        Query
                    </a>
                    {' | '}
                    <a
                        id="show-results"
                        onClick={revealQuery}
                        className="action"
                    >
                        Results
                    </a>
                    {' | '}
                    <a
                        id="show-readable"
                        onClick={readableQuery}
                        className="action"
                    >
                        Summary
                    </a>
                    )
                </div>
                <div className="card-body">
                    {showQuery && (
                        <Form.Control
                            as="textarea"
                            className="query-code"
                            rows={5}
                            value={squery}
                            readOnly={true}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

const QueryRow = ({
    n,
    id,
    delfunc,
    storedObj = {},
    totalrows = 1,
    stopAdd = null,
    submit,
}) => {
    let initialRowType = 'normal';
    if (storedObj?.isdate) {
        initialRowType = 'date';
    }
    if (!storedObj?.isdate && storedObj?.searchDisabled) {
        initialRowType = 'assettype';
    }
    const [rowType, setRowType] = useState(initialRowType);
    const [hasSearchBox, setHasSearchBox] = useState(true);
    const searchBoxRef = useRef();
    const dateSelect = useRef();
    useEffect(() => {
        if (rowType === 'date') {
            const choice = dateSelect?.current?.value;
            setHasSearchBox(SC.needsDateString(choice));
        } else if (rowType === 'assettype') {
            setHasSearchBox(false);
        } else {
            setHasSearchBox(true);
        }
    }, [rowType]);
    const deleteme = () => {
        delfunc(id);
    };
    return (
        <div className="advsrch-form-row" id={id} data-n={n}>
            <div>
                {n > 0 && (
                    <ConnectorSelect
                        id={`advconn-${n}`}
                        n={n}
                        className="connector"
                        connector={
                            storedObj?.conn ? parseInt(storedObj.conn) : 1
                        }
                    />
                )}
            </div>
            <div className="field-select">
                <FieldSelect
                    id={`advfield-${n}`}
                    setType={setRowType}
                    className="field"
                    initChoice={
                        storedObj?.field ? parseInt(storedObj.field) : 10
                    }
                    submit={submit}
                />
            </div>
            <div>
                {rowType === 'normal' && (
                    <ScopeSelect
                        id={`advscope-${n}`}
                        className="scope"
                        initScope={
                            storedObj?.scope ? parseInt(storedObj.scope) : 50
                        }
                        submit={submit}
                    />
                )}
                {rowType === 'date' && (
                    <DateScopeSelect
                        id={`advscope-${n}`}
                        className="scope"
                        setSearchBox={setHasSearchBox}
                        selel={dateSelect}
                        initScope={
                            storedObj?.scope ? parseInt(storedObj.scope) : 55
                        }
                        submit={submit}
                    />
                )}
                {rowType === 'assettype' && (
                    <AssetTypeSelect
                        id={`advscope-${n}`}
                        className="scope asset"
                        setSearchBox={setHasSearchBox}
                        selel={dateSelect}
                        initScope={storedObj?.scope ?? 'audio-video'}
                        submit={submit}
                    />
                )}
            </div>
            <div>
                <SearchBox
                    id={`advtext-${id}`}
                    sbel={searchBoxRef}
                    disable={!hasSearchBox}
                    className={'textbox'}
                    val={storedObj?.text}
                    stopAdd={stopAdd}
                />
            </div>
            <div className="row-delete">
                {(n > 0 || totalrows > 1) && (
                    <MdDeleteForever onClick={deleteme} />
                )}
            </div>
        </div>
    );
};

const ConnectorSelect = ({ id, name = false, n, connector }) => {
    const [connVal, setConnVal] = useState(connector);
    name = name || id;
    return (
        <select
            id={id}
            name={name}
            value={connVal}
            onChange={(event) => {
                setConnVal(event.target.value);
            }}
        >
            <option value={SC.AND}>And</option>
            <option value={SC.ANDNOT}>And Not</option>
            <option value={SC.OR}>Or</option>
        </select>
    );
};

const FieldSelect = ({ id, setType, name = false, initChoice, submit }) => {
    const [selectedValue, setSelectedValue] = useState(initChoice);
    const setTree = treeStore((state) => state.setTree);
    const setBrowse = browseSearchToggle((state) => state.setBrowse);
    const setOpenTab = openTabStore((state) => state.changeButtonState);

    const handleBtnClick = (e) => {
        e.preventDefault();
        setOpenTab(2);
        setBrowse();
        const treeEnum = {
            13: 'places',
            14: 'subjects',
            15: 'terms',
        };
        setTree(treeEnum[selectedValue]);
    };
    name = name || id;
    const selel = useRef();
    const ichanged = () => {
        const choice = selel.current.value * 1;
        // console.log('ichanged', choice);
        setSelectedValue(choice);
        if (SC.isDate(choice)) {
            setType('date');
        } else if (choice === SC.RESOURCE_TYPE) {
            setType('assettype');
        } else {
            setType('normal');
        }
    };
    const fnms = SC.fieldLookup;
    const opts = [
        SC.ANY,
        SC.TITLE,
        SC.RESOURCE_TYPE,
        SC.PERSON,
        SC.REL_PLACES,
        SC.REL_SUBJECTS,
        SC.REL_TERMS,
        SC.PUB_PLACE,
        SC.PUBLISHER,
        SC.IDS,
        SC.CREATE_DATE,
        SC.ENTRY_DATE,
    ];
    return (
        <>
            <select
                id={id}
                name={name}
                ref={selel}
                value={selectedValue}
                onChange={ichanged}
                onKeyUp={(e) => {
                    if (e.key === 'Enter') {
                        submit(e);
                    }
                }}
            >
                {opts.map((opt, n) => {
                    return (
                        <option value={opt} key={`advsrch-field-option-${n}`}>
                            {fnms[opt]}
                        </option>
                    );
                })}
            </select>
            {selectedValue && [13, 14, 15].includes(selectedValue) && (
                <span>
                    <button
                        className="btn btn-warning btn-sm"
                        onClick={handleBtnClick}
                    >
                        Open Related Tree
                    </button>
                </span>
            )}
        </>
    );
};

const ScopeSelect = ({ id, name = false, initScope, submit }) => {
    const [scopeVal, setScopeVal] = useState(initScope);
    name = name || id;
    return (
        <select
            id={id}
            name={name}
            value={scopeVal}
            onChange={(event) => setScopeVal(event.target.value)}
            onKeyUp={(e) => {
                if (e.key === 'Enter') {
                    submit(e);
                }
            }}
        >
            <option value={SC.CONTAINS}>Contains</option>
            <option value={SC.EXACTLY}>Exactly</option>
            <option value={SC.STARTSWITH}>Starts With</option>
            <option value={SC.ENDSWITH}>Ends With</option>
        </select>
    );
};

const SearchBox = ({ id, disable, name = false, val = '', stopAdd = null }) => {
    const [searchVal, setSearchVal] = useState(val);
    name = name || id;
    const cnm = disable ? 'd-none' : 'd-block';
    return (
        <input
            type="text"
            id={id}
            name={name}
            value={searchVal}
            disabled={disable}
            className={cnm}
            onChange={(event) => {
                setSearchVal(event.target.value);
            }}
            onKeyDown={(event) => {
                if (event.key === 'Enter' && stopAdd !== null) {
                    stopAdd(true);
                }
            }}
        />
    );
};

const DateScopeSelect = ({
    id,
    setSearchBox,
    selel,
    name = false,
    initScope,
    submit,
}) => {
    const [scopeVal, setScopeVal] = useState(initScope);
    name = name || id;
    const ichanged = (event) => {
        const selectedVal = event.target.value;
        setScopeVal(selectedVal);
        const choice = selel.current.value;
        if (SC.needsDateString(choice)) {
            setSearchBox(true);
        } else {
            setSearchBox(false);
        }
    };
    return (
        <select
            id={id}
            name={name}
            ref={selel}
            value={scopeVal}
            onChange={ichanged}
            onKeyUp={(e) => {
                if (e.key === 'Enter') {
                    submit(e);
                }
            }}
        >
            <option value={SC.LAST1YEAR}>in last year</option>
            <option value={SC.LAST5YEARS}>in last 5 years</option>
            <option value={SC.LAST10YEARS}>in last 10 years</option>
            <option value={SC.EXACTLY}>exactly</option>
            <option value={SC.BETWEEN}>between</option>
        </select>
    );
};

const AssetTypeSelect = ({
    id,
    setSearchBox,
    selel,
    name = false,
    initScope,
}) => {
    const [scopeVal, setScopeVal] = useState(initScope);
    name = name || id;
    return (
        <select
            id={id}
            name={name}
            ref={selel}
            className="asset-select"
            value={scopeVal}
            onChange={(event) => setScopeVal(event.target.value)}
        >
            {Object.keys(SC?.ASSET_TYPES).map((ak, ki) => {
                return (
                    <option key={`atopt-${ki}`} value={ak}>
                        {SC?.ASSET_TYPES[ak]}
                    </option>
                );
            })}
        </select>
    );
};

export default AdvancedSearch;
